<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.menu.expense_request_history") }}
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          class="elevation-1"
        >
          <template #item="row">
            <tr :active="row.selected">
              <td><employee-profile :employee="row.item.expense.employee" /></td>
              <td>{{ row.item.expense.name }}</td>
              <td>{{ row.item.expense.type.name }}</td>
              <td>{{ $helpers.getConvertCurrency(row.item.expense.requested) }}</td>
              <td>{{ $helpers.getConvertCurrency(row.item.expense.approved) }}</td>
              <td>
                {{ row.item.expense.timestamp | formattedDate }}
              </td>
              <td>{{ row.item.expense.description }}</td>
              <td>
                <template v-if="row.item.expense.travel">
                  <p class="ma-0 caption">
                    <v-icon size="20" color="teal">mdi-account</v-icon>
                    {{ row.item.expense.travel.employee.firstName }}
                    {{ row.item.expense.travel.employee.lastName }}
                  </p>
                  <p class="ma-0 caption">
                    <v-icon size="20" color="teal">mdi-map-marker</v-icon>{{ row.item.expense.travel.address }}
                  </p>
                  <p class="ma-0 caption">
                    <v-icon size="20" color="teal">mdi-calendar-range</v-icon>
                    {{ row.item.expense.travel.beginDate | formattedDateTimeMinute }} -
                    {{ row.item.expense.travel.endDate | formattedDateTimeMinute }}
                  </p>
                </template>
                <v-icon v-else color="red">mdi-close</v-icon>
              </td>
              <td>
                <template v-if="row.item.expense.expenseManagement">
                  <p class="ma-0">
                    {{ row.item.expense.expenseManagement.name }}
                  </p>
                  <p v-if="row.item.expense.expenseManagement.project" class="ma-0 caption">
                    <v-avatar size="14" :color="row.item.expense.expenseManagement.project.color" />
                    {{ row.item.expense.expenseManagement.project.name }}
                  </p>
                </template>
                <v-icon v-else color="red">mdi-close</v-icon>
              </td>
              <td>
                <request-status-label :status="row.item.expense.requestStatus" />
              </td>
              <td>
                <file-download-button :url="row.item.expense.attachment" />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-01-13 14:02:37
   * @modify date 2021-01-13 14:02:37
   * @desc ExpenseRequestHistory
   */
  import { APPROVAL_EXPENSE_REQUESTS } from "../../query";

  export default {
    name: "ExpenseRequestHistory",
    components: { RequestStatusLabel: () => import("@/components/global/request-status-label") },
    data: vm => ({
      table: {
        loading: false,
        data: [],
        criteria: {},
        options: {},
        pagination: {
          rowsPerPage: 10,
          sortBy: "createdDateTime",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("global.employee"),
            value: "expense.employee.firstName",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.expense.name"),
            value: "expense.name",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.expense.type"),
            value: "expense.type.name",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.expense.amount"),
            value: "expense.requested.amount",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.expense.approved_amount"),
            value: "expense.approved.amount",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.expense.date"),
            value: "expense.timestamp",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.description"),
            value: "expense.description",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.expense.travel"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.expense_management"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.expense.status"),
            value: "expense.requestStatus",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: "",
            align: "left",
            sortable: false,
            value: "url",
            class: ["primary--text"],
            width: "5%"
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: APPROVAL_EXPENSE_REQUESTS,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!errors && !data.error) {
              this.table.data = data.employee.approvalExpenseRequests || [];
              this.table.totalItems = data.employee.approvalExpenseRequests.length || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>

<style scoped></style>
